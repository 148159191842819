import { SET_SUGGESTIONS, SELECT_SUGGESTION, SET_SCRIPT_LOADING, SET_AVATAR } from "../actionTypes";
import { handleActions } from 'redux-actions';

const initialState = {
  suggestions: [],
  selected: {
    pick_up: {},
    drop_off: {}
  },
  scriptLoading: false,
  avatar: true
};

const commonReducer = handleActions (
  {
    [SET_SUGGESTIONS]: (state, { payload }) => ({
      ...state,
      suggestions: payload
    }),
    [SELECT_SUGGESTION]: (state, { payload }) => ({
      ...state,
      selected: {
        ...state.selected,
        [payload.key]: payload.suggestion
      }
    }),
    [SET_SCRIPT_LOADING]: (state, {payload}) => ({
      ...state,
      scriptLoading: payload
    }),
    [SET_AVATAR]: (state, {payload}) => ({
      ...state,
      avatar: payload
    })
  },
  initialState
);

export default commonReducer;