import React, { useEffect } from "react";
import { withRouter } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import FullWidthLayout from './components/layouts/FullWidthLayout';
import AnalyticsWrapper from './components/common/AnalyticsWrapper';
import './scss/index.scss'
import { Helmet } from "react-helmet";

const App = () => {
  useEffect(() => {
    return () => {
      try {
        localStorage.removeItem('persist:root');
      } catch (e) {
        return false;
      }
    } //eslint-disable-next-line
  }, [])
  useEffect(() => {
    const stage = process.env.REACT_APP_STAGE;
    if (
      stage === 'demo' ||
      stage === 'test' ||
      (window.location.href.indexOf('/moving/') > -1) ||
      (window.location.href.indexOf('/distance') > -1) ||
      (window.location.href.indexOf('/addresses') > -1) ||
      (window.location.href.indexOf('/move-date') > -1) ||
      (window.location.href.indexOf('/move-time') > -1) ||
      (window.location.href.indexOf('/home-size') > -1)
    ) {
      const meta = document.createElement('meta');
      meta.name = "robots";
      meta.content = "nofollow, noindex";
      document.getElementsByTagName('head')[0].appendChild(meta);
    }
  }, [])
  return (
    <AnalyticsWrapper>
      <Helmet>
        <title>Compare Moving Quotes & Book Online | Unpakt</title>
        <meta name="description" content="Save time and money! Instantly compare moving quotes and guaranteed prices from the best moving companies online, with real customer reviews." />
        <meta name="robots" content="nofollow, noindex" />
      </Helmet>
      <div className="font-proxima">
        <FullWidthLayout />
      </div>
    </AnalyticsWrapper>
  )
}

export default withRouter(App);