import React, { useEffect, lazy, Suspense } from 'react'
// import LandingImage from '../landing/LandingImage'
import LandingImageOld from './LandingImageOld';
import { connect } from 'react-redux';
import { setAvatar } from '../../redux/actions/common'

const AsyncNumbers = lazy(() => import("./Numbers"));
const AsyncFeatures = lazy(() => import("./Features"));
const AsyncNews = lazy(() => import("./News"));
const AsyncHowItWorks = lazy(() => import("./HowItWorksCityPage"));
const AsyncServices = lazy(() => import("./Services"));
const AsyncReferralSection = lazy(() => import("./ReferralSection"));
const AsyncFooter = lazy(() => import("./Footer"));

const Landing = (props) => {
  useEffect(() => {
    props.setAvatar(false)
    // sessionStorage.setItem('landing', 'landing')
    //eslint-disable-next-line
  }, [])
  return (
    <div style={{ background: '#27c8ea' }}>
      <LandingImageOld />      
      <Suspense fallback={''}><AsyncNumbers viewModel={'mobile'} /></Suspense>
      <Suspense fallback={''}><AsyncFeatures viewModel={'mobile'} /></Suspense>
      <div className="bg-white">
        <div className="container">
          <Suspense fallback={''}><AsyncNews viewModel={'mobile'} /></Suspense>
        </div>
        <Suspense fallback={''}><AsyncHowItWorks viewModel={'mobile'} hideButton={true} /></Suspense>
        <Suspense fallback={''}><AsyncServices viewModel={'mobile'} /></Suspense>
        <Suspense fallback={''}><AsyncReferralSection viewModel={'mobile'} /></Suspense>
        <Suspense fallback={''}><AsyncFooter viewModel={'mobile'} /></Suspense>
      </div>
    </div>
  )
}

export default connect(null, { setAvatar })(Landing);