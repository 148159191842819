import React, { useEffect, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PageNotFound from '../common/PageNotFound';
import Header from '../common/Header';
import ForgotPassword from '../start/auth/ForgotPassword';
import { checkCookies } from '../../redux/actions/auth';
import MovingContainer from '../newMoving/MovingContainer';
import Legal from '../common/Legal';
import Landing2 from '../landing2/Landing2'
import CreateAccount from '../newMoving/reviewTotal/CreateAccount'
import LoginAccount from '../newMoving/reviewTotal/LoginAccount'
// import Landing from '../landing/Landing'

const Distance = lazy(() => import('../movingDetails/distance/Distance'));
const MovingAddresses = lazy(() => import('../movingDetails/address/MovingAddresses'));
const MoveDate = lazy(() => import('../movingDetails/moveDate/MoveDate'));
const MoveTime = lazy(() => import('../movingDetails/moveDate/MoveTime'));
const HomeSize = lazy(() => import('../movingDetails/homeSize/HomeSize'));
const PriceInfo = lazy(() => import('../newMoving/inventory/PriceBasedOnInventory'));
const HowPricingWorks = lazy(() => import('../newMoving/compare/HowPricingWorks'));

const mapStateToProps = ({ movePlan, common }) => ({
  mpid: movePlan.currentMPID,
  avatar: common.avatar
});
const FullWidthLayout = props => {
  //eslint-disable-next-line
  useEffect(() => props.checkCookies(document.cookie), [])

  return (
    <div className="app-container">
      <Header avatar={props.avatar} />
      <div className="mobile-app-container">
        <Switch>
          <Route exact path="/" component={Landing2} />
          {/* <Route exact path="/landing" component={Landing2} /> */}
          <Route path="/distance" render={() => <Suspense fallback={null} ><Distance /></Suspense>} />
          <Route path="/addresses" render={() => <Suspense fallback={null} ><MovingAddresses /></Suspense>} />
          <Route path="/move-date" render={() => <Suspense fallback={null} ><MoveDate /></Suspense>} />
          <Route path="/move-time" render={() => <Suspense fallback={null} ><MoveTime /></Suspense>} />
          <Route path="/home-size" render={() => <Suspense fallback={null} ><HomeSize /></Suspense>} />
          <Route path="/price-info" render={() => <Suspense fallback={null} ><PriceInfo /></Suspense>} />
          <Route exact path="/how-price-works" render={() => <Suspense fallback={null} ><HowPricingWorks /></Suspense>} />          
          <Route exact path="/login" component={LoginAccount} />
          <Route exact path="/create-account" component={CreateAccount} />
          <Route exact path="/forgot-password" render={() => <Suspense fallback={null} ><ForgotPassword /></Suspense>} />
          <Route exact path="/legal" render={() => <Suspense fallback={null} ><Legal /></Suspense>} />
          
          <Route path="/moving/:mpid/" component={MovingContainer} />       
          <Route component={PageNotFound} />
        </Switch>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, { checkCookies })(FullWidthLayout)
