import React, { useEffect } from 'react'
import CloseButtonRow from '../../common/CloseButtonRow'
import CaseyWords from '../../movingDetails/CaseyWords'
import { useFormik } from 'formik';
import ThirdPartySignin from './ThirdPartySignin';
import { connect } from 'react-redux';
import { signIn, setErrorMessage, setAuthSubmitting } from '../../../redux/actions/auth';
import ReactLoading from 'react-loading';

const mapStateToProps = ({ auth }) => ({
  isEmailTaken: auth.isEmailTaken,
  submitting: auth.submitting,
  apiErrorText: auth.authErrorText
});

const LoginAccount = (props) => {  
  const mpid = props.match.params.mpid
  
  useEffect(() => {
    props.setErrorMessage('');
    props.setAuthSubmitting(false);
    //eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    handleSubmit: (e) => e.preventDefault(),
    onSubmit: values => {      
      if (mpid) {
        props.signIn(values, { redirect: `/moving/${mpid}/review-total` });    
      } else if (!!sessionStorage.getItem('prevPage')) {        
        let prevPage = sessionStorage.getItem('prevPage')
        props.signIn(values, { redirect: prevPage });
      } else {
        props.signIn(values, { redirect: 'old-landing' });
      }      
    },
    validateOnBlur: true,
    validateOnChange: false,
    validate: values => {
      let errors = {};
      if (!values.email) {
        errors.email = 'Email is required'
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = `"${values.email}" is not a valid email address.`
      }
      if (!values.password) errors.password = 'Password is required'
      return errors
    }
  });

  return (
    <div className='moving-details-padding' >
      {mpid ? 
      <CloseButtonRow prevPath='review-total' mpid={mpid} /> 
      : 
      <div style={{ height: '8vh' }}></div>
      }
      <CaseyWords words='Let’s sign in an account to save your move plan' />
      <div className="create-account__container mt-3">
        <p className='hel-font__text create-account__title'>Email Address</p>
        {!!props.apiErrorText &&
          <p className="f-s-xs-14 text-center mt-0 mb-1 red">{props.apiErrorText}</p>
        }
        <form onSubmit={formik.handleSubmit}>
          <div className={`account-info__row`}>
            <input
              name="email"
              type="text"
              value={formik.values.email}
              placeholder='example@youremail.com'
              className='account-info__input hel-font__text'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={() => formik.touched}
              required
            />
            {formik.errors.email && formik.touched.email && <div className='red-error-message'>{formik.errors.email}</div>}
          </div>
          <div className='account-info__row'>
            <input
              name="password"
              type="password"
              placeholder='Password'
              className='account-info__input hel-font__text'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={() => formik.touched}
              value={formik.values.password}
              required
            />
            {formik.errors.password && formik.touched.password && <div className='red-error-message'>{formik.errors.password}</div>}
          </div>

          <button
            className='button--long--round hel-font__text'
            style={{ fontSize: '2.3vh' }}
            type="submit"
            disabled={props.submitting}
          >
            {props.submitting ?
              <ReactLoading className="m-auto" type={"spokes"} color={"#ddd"} height={20} width={20} />
              :
              'SIGN IN'
            }
          </button>
        </form>
      </div>

      <ThirdPartySignin />
    </div>
  )
}

export default connect(mapStateToProps, { signIn, setErrorMessage, setAuthSubmitting })(LoginAccount)
