import React from 'react'
import { Link } from 'react-router-dom';

const LandingImageOld = () => {
  return (
    <div className='unpakt-mainsite__container'>
      <div className='title__container'>
        <h1>
          Book <span className="unpakt-orange">your move</span> online in minutes!
        </h1>
        <p>Compare guaranteed moving quotes from the best moving companies</p>
        <p>Book online & save 45% on your moving costs</p>
      </div>
      <div className='get-quotes__container'>
        <Link to="/distance">
          <button>Get Quotes</button>
        </Link>
      </div>
    </div>
  )
}

export default LandingImageOld
