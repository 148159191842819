import {
  BOOK,
  CHECK_PROMO,
  INIT_BOOK_PAGE,
  INIT_PAYPAL_PAGE,
  NETWORK_SUCCESS,
  SUBMIT_BOOK,
  NETWORK_FAILURE
} from '../actionTypes';
import { apiRequest } from '../actions/api';
import {
  setBookContact,
  setCardErrors,
  setClientToken,
  submitBook,
  setBookNonce,
  setPromoLoading,
  setPaypalComplete,
  setPaypalEmail,
  setBookLoading,
  checkPromo,
} from '../actions/book';
import { getCurrentMoveplan, setCurrentMoveplanID, updateMovePlan } from '../actions/movePlan';
import client from 'braintree-web/client';
import hostedFields from 'braintree-web/hosted-fields';
import { setCurrentStep } from '../actions/funnel';
import { push } from 'connected-react-router';
import has from 'lodash-es/has';
import paypalCheckout from 'braintree-web/paypal-checkout';
import { registerUser, signIn, setErrorMessage } from '../actions/auth';
import { openModal, closeAllModals } from '../actions/modals';
import { setCouponData, setInvalidCoupon } from '../actions/analytics';
import { getTotalCost } from '../reducers/book';

const bookMiddleware = ({ getState, dispatch }) => next => action => {
  next(action);

  if (action.type === INIT_BOOK_PAGE) {
    dispatch(setBookLoading(true));
    if (getState().movePlan.currentMPID !== action.payload) {
      dispatch(setCurrentMoveplanID(action.payload));
    }
    dispatch(getCurrentMoveplan());
    dispatch(setCurrentStep('BOOK'));
    dispatch(setBookNonce());
    if (has(getState().analytics.couponData, 'code')) {
      dispatch(checkPromo(getState().analytics.couponData.code));
    }
    if (!!getState().auth.authErrorText) dispatch(setErrorMessage(""))
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/bookings/client_token`,
        method: 'GET',
        fromAction: INIT_BOOK_PAGE
      })
    );
  }

  if (action.type === INIT_PAYPAL_PAGE) {
    dispatch(setBookLoading(true));
    if (getState().movePlan.currentMPID !== action.payload) {
      dispatch(setCurrentMoveplanID(action.payload));
    }
    dispatch(getCurrentMoveplan());
    dispatch(setCurrentStep('BOOK'));
    dispatch(setBookNonce());
    if (has(getState().analytics.couponData, 'code')) {
      dispatch(checkPromo(getState().analytics.couponData.code));
    }
    if (!!getState().auth.authErrorText) dispatch(setErrorMessage(""))
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/bookings/client_token`,
        method: 'GET',
        fromAction: INIT_PAYPAL_PAGE
      })
    );
  }

  if (action.type === CHECK_PROMO) {
    dispatch(setPromoLoading(true));
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/check_coupon`,
        method: 'GET',
        params: {
          code: action.payload
        },
        fromAction: CHECK_PROMO
      })
    )
  }

  if (action.type === BOOK) {
    const values = action.payload;

    dispatch(setBookLoading(true));
    if (!has(getState().auth, 'user')) {
      if (!!values.registrationEmail && !!values.newPassword1 && !!values.newPassword2) {
        dispatch(registerUser(
          {
            email: values.registrationEmail,
            password: values.newPassword1,
            password_confirmation: values.newPassword2
          },
          { context: 'book' }
        ));
      } else {
        dispatch(signIn({
          email: values.loginEmail,
          password: values.password
        }, { context: 'book' }
        ));
      }
    }
    let payment = {
      first_name: values.fname,
      last_name: values.lname
    }
    dispatch(setBookContact({
      contact_information: {
        name: values.name,
        phone_number: values.phoneNumber
      },
      payment: payment
    }));
    window.sessionStorage.setItem('contact_information', JSON.stringify({ name: values.name, phone_number: values.phoneNumber }))
    // if(values.user_note) {
    //   dispatch(updateMovePlan({ user_note: values.user_note}));
    // }    
    if (has(getState().auth, 'user') && has(getState().book.bookPayload.payment, 'billing_nonce') && !!getState().book.bookPayload.payment.billing_nonce) {
      // PAYPAL 
      // dispatch(openModal({ name: 'loader', properties: { state: 'book' }}));
      dispatch(submitBook());
    } else {
      // BRAINTREE
      let bookForm = document.getElementById('book-form');
      const submitBookEvent = new CustomEvent('runBraintreeAuth', { bubbles: true });
      bookForm.dispatchEvent(submitBookEvent);
    }
  }

  if (action.type === SUBMIT_BOOK) {
    let payload = getState().book.bookPayload;
    if (has(getState().analytics.couponData, 'coupon') && getState().analytics.couponData.coupon.success) {
      payload.payment.coupon = getState().analytics.couponData.coupon.code
    }
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/bookings`,
        method: 'POST',
        params: payload,
        fromAction: SUBMIT_BOOK
      })
    );
  }

  if (action.type === NETWORK_SUCCESS) {
    switch (action.meta.originalRequest.fromAction) {
      case CHECK_PROMO:
        if (action.payload.response.data.coupon.success) {
          dispatch(setCouponData(action.payload.response.data))
          dispatch(setInvalidCoupon(false));
          dispatch(updateMovePlan({ promo_code: action.payload.response.data.coupon.code }));
        } else {
          dispatch(setInvalidCoupon(true));
        }
        dispatch(setPromoLoading(false));
        break;

      case INIT_BOOK_PAGE:
        dispatch(setClientToken(action.payload.response.data.braintree_client_token));
        client.create({
          authorization: action.payload.response.data.braintree_client_token
        }, function (err, clientInstance) {
          if (err) {
            console.error('Error creating braintree client', err);
            return;
          }
          hostedFields.create({
            client: clientInstance,
            styles: {
              'input': {
                'color': '#042b3b',
                'font-size': '30vh'
              },
              '.valid': {
                'color': 'green'
              },
              '.invalid': {
                'color': 'red',
                'border-color': 'red'
              },
            },
            fields: {
              number: {
                selector: '#card-number',
                placeholder: 'Card Number',
              },
              cvv: {
                selector: '#cvv',
                placeholder: 'CVV/CVC'
              },
              expirationDate: {
                selector: '#expiration-date',
                placeholder: 'MM/YY'
              },
              postalCode: {
                selector: '#postal-code',
                placeholder: 'Billing Zip'
              }
            }
          }, function (err, hostedFieldsInstance) {
            if (err) {
              console.error(err);
              return;
            }
            // dispatch(setHostedFieldsInstance(hostedFieldsInstance));
            hostedFieldsInstance.on('focus', function (event) {
              dispatch(setCardErrors({ [event.emittedBy]: false }));
            });
            hostedFieldsInstance.on('blur', function (event) {
              let field = event.fields[event.emittedBy];
              let errors = {};
              switch (event.emittedBy) {
                case 'number':
                  if (!field.isValid) errors.number = 'Invalid card number';
                  else errors.number = false
                  dispatch(setCardErrors(errors));
                  return -1;
                case 'expirationDate':
                  if (!field.isValid) errors.expirationDate = 'Invalid date';
                  else errors.expirationDate = false;
                  dispatch(setCardErrors(errors));
                  return -1;
                case 'cvv':
                  if (!field.isValid) errors.cvv = 'Invalid code';
                  else errors.cvv = false;
                  dispatch(setCardErrors(errors));
                  return -1;
                case 'postalCode':
                  if (!field.isValid) errors.postalCode = 'Invalid Postal Code';
                  else errors.postalCode = false;
                  dispatch(setCardErrors(errors));
                  return -1;
                default:
                  dispatch(setCardErrors({}));
                  break;
              }
            });
            let elem = document.getElementById("book-form");
            elem.addEventListener("runBraintreeAuth", function () {
              //check for paypal or splitit payment
              if (has(getState().book.bookPayload, 'payment') && !!getState().book.bookPayload.payment.billing_nonce) {
                return
              }
              // Check that the form is valid
              let state = hostedFieldsInstance.getState();
              let isFormValid = Object.keys(state.fields).every(key => state.fields[key].isValid);
              if (isFormValid) {
                hostedFieldsInstance.tokenize(function (err, payload) {
                  if (err) {
                    console.error(err);
                    dispatch(setCardErrors({ err }));
                    return;
                  } else if (!!payload) {
                    dispatch(setBookNonce(payload.nonce));

                    if (has(getState().auth, 'user')) {
                      dispatch(submitBook());
                    }
                  }
                  return
                });
              } else {
                let errors = {}
                Object.keys(state.fields).map(function (key) {
                  if (!state.fields[key].isValid) errors[key] = 'Required'
                  return state.fields[key].isValid;
                });
                dispatch(closeAllModals());
                dispatch(setCardErrors(errors))
                dispatch(setBookLoading(false));
              }
            });
          });      
        });
        dispatch(setBookLoading(false));
        break;

      case INIT_PAYPAL_PAGE:
        dispatch(setClientToken(action.payload.response.data.braintree_client_token));
        client.create({
          authorization: action.payload.response.data.braintree_client_token
        }, function (err, clientInstance) {
          if (err) {
            console.error('Error creating braintree client', err);
            return;
          }
          paypalCheckout.create({
            client: clientInstance
          }, function (paypalCheckoutErr, paypalCheckoutInstance) {
            if (paypalCheckoutErr) {
              console.error('Error creating PayPal Checkout:', paypalCheckoutErr);
              return;
            }
            const getPaypalScript = () => {
              return new Promise((resolve, reject) => {
                let script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_ID}&vault=true${process.env.REACT_APP_STAGE === 'prod' ? '' : '&debug=true'}`
                script.addEventListener('load', () => resolve(script), false);
                script.addEventListener('error', () => reject(script), false);
                document.body.appendChild(script);
              })
            }
            const initPaypal = async () => {
              //eslint-disable-next-line
              let t = await getPaypalScript();
              return window.paypal.Buttons({
                env: process.env.REACT_APP_STAGE === 'prod' ? 'production' : 'sandbox',
                style: {
                  color: "blue",
                  layout: "horizontal",
                  tagline: "false"
                },
                createBillingAgreement: function () {
                  return paypalCheckoutInstance.createPayment({
                    flow: 'vault',
                    amount: getTotalCost(getState().movePlan, getState().analytics), // Required.
                    currency: 'USD', // Required
                  });
                },

                onApprove: function (data, actions) {
                  // some logic here before tokenization happens below
                  return paypalCheckoutInstance.tokenizePayment(data).then(function (payload) {
                    if (payload) {
                      dispatch(setPaypalEmail(payload.details.email))
                      dispatch(setBookNonce(payload.nonce));
                      dispatch(setPaypalComplete(true));
                    }
                  });
                },
                onCancel: function () {
                  dispatch(setBookNonce());
                },
                onError: function (err) {
                  // handle case where error occurs
                  console.log(err)
                }
              }).render('#paypal-button');
            }
            initPaypal();
          });
        });
        dispatch(setBookLoading(false));
        break;

      case SUBMIT_BOOK:
        let plan = getState().movePlan.currentPlan
        let name, sku, suffix;
        if (plan.details.move_into_storage) {
          name = 'Move Into Storage'
          sku = 'moving-to-storage'
          suffix = 'mis'
        } else if (plan.details.storage_in_transit) {
          name = 'Move with SIT'
          sku = 'moving-sit'
          suffix = 'sit'
        } else {
          name = 'Move job'
          sku = ''
          suffix = 'move'
        }
        const coupon = has(getState().analytics.couponData, 'coupon') ? getState().analytics.couponData.coupon.code : ''
        // Google Adwords and facbook pixel
        if (!!window.analytics) {
          // this tracks to google adwords
          window.analytics.track('Order Completed', {
            checkout_id: plan.uuid,
            order_id: plan.uuid,
            affiliation: 'Unpakt Funnel',
            total: plan.pricing.total_cost,
            revenue: plan.pricing.total_cost,
            shipping: 0,
            tax: 0,
            coupon: coupon,
            currency: 'USD',
            products: [
              {
                product_id: `${plan.uuid}-${suffix}`,
                sku: sku,
                name: name,
                price: plan.pricing.total_cost,
                quantity: 1
              }
            ]
          });
          // Custom event for Facebook Conversion
          // window.analytics.track('Booked Move', {
          //   checkout_id: plan.uuid,
          //   order_id: plan.uuid,
          //   affiliation: 'Unpakt Funnel',
          //   total: plan.pricing.total_cost,
          //   revenue: plan.pricing.total_cost,
          //   shipping: 0,
          //   tax: 0,
          //   coupon: coupon,
          //   currency: 'USD',
          //   products: [
          //     {
          //       product_id: `${plan.uuid}-${suffix}`,
          //       sku: sku,
          //       name: name,
          //       price: plan.pricing.total_cost,
          //       quantity: 1
          //     }
          //   ]
          // });
        }
        dispatch(setBookLoading(false));
        // dispatch(gotoCongrats());
        dispatch(push(`/moving/${getState().movePlan.currentMPID}/plan`))
        break;
      default:
        break;
    }
  }

  if (action.type === NETWORK_FAILURE) {
    switch (action.meta.originalRequest.fromAction) {
      case SUBMIT_BOOK:
        if (action.payload.response.response.status === 422) {
          dispatch(closeAllModals());
          dispatch(openModal({
            name: 'apiError',
            properties: {
              error: 'There was a problem processing this transaction. Please try again, or use another payment method.',
              cardError: true
            }
          }))
          dispatch(setBookLoading(false));
        }
        break;
      default:
        break;
    }
  }
};

export default bookMiddleware;
