import React from 'react'
import { connect } from 'react-redux';

const mapStateToProps = ({ movePlan, funnel, router }) => ({
  uuid: movePlan.currentMPID,
  fromBooking: funnel.currentStep === "BOOK",
  pathName: router.location.pathname
});
const ThirdPartySignin = (props) => {
  const returnUrl = () => {
    if (!!props.uuid) return process.env.REACT_APP_BASE_URL + props.pathName
    else return process.env.REACT_APP_SUPPORT_URL + "/my-home"
  }
  const handleGoogleSignin = () => {
    window.location.href = `${process.env.REACT_APP_BASE_API_URL}/users/auth/google_oauth2?redirect_url=${returnUrl(!!props.fromBooking)}${!!props.currentMPID ? props.currentMPID : ''}`
  }
  const handleFacebookSignin = () => {
    window.location.href = `${process.env.REACT_APP_BASE_API_URL}/users/auth/facebook?redirect_url=${returnUrl(!!props.fromBooking)}${!!props.currentMPID ? props.currentMPID : ''}`
  }
  return (
    <>
      <div className='break-line'>
        <div className='line'></div> <p>Or</p> <div className='line'></div>
      </div>
      <div className='third-party-login' onClick={handleGoogleSignin}> <img src="/img/common/google.svg" alt="google" /> <p>Google</p></div>
      <div className='third-party-login' onClick={handleFacebookSignin}> <img src="/img/common/facebook.svg" alt="facebook" /> <p>Facebook</p></div>
    </>
  )
}

export default connect(mapStateToProps, {})(ThirdPartySignin)
