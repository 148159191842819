import React from 'react'

const CaseyWords = ({ words, isWhite }) => {
	return (
		<div className={`casey-words__container ${isWhite && 'casey-words__container--white'}`}>
			<p>{words}</p>
		</div>
	)
}

export default CaseyWords
