import React from 'react'
import { Link } from 'react-router-dom';

const CloseButtonRow = ({ prevPath, mpid }) => {
  return (
    <div className='close__container'>
      {mpid ?
        <Link to={`/moving/${mpid}/${prevPath}`}></Link>
        :
        <Link to={`/${prevPath}`}></Link>
      }
    </div>
  )
}

export default CloseButtonRow
