// TODO: remove this directory and import as node_modules

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import history from './history';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import funnelMiddleware from './middleware/funnel';
import apiMiddleware from './middleware/api';
import inventoryMiddleware from './middleware/inventory';
import movePlanMiddleware from "./middleware/movePlan";
import modalsMiddleware from "./middleware/modals";
import compareMiddleware from "./middleware/compare";
import consultationsMiddleware from "./middleware/consultations";
import bookMiddleware from './middleware/book';
import authMiddleware from './middleware/auth';
import detailsMiddleware from './middleware/details';
import planMiddleware from './middleware/plan';
import moverMiddleware from './middleware/mover';
import congratsMiddleware from './middleware/congrats';
import socialMiddleware from './middleware/social';
import myHomeMiddleware from './middleware/myHome';
import createRootReducer from './reducers/root';
import commonMiddleware from './middleware/common';
import analyticsMiddleware from './middleware/analytics';

const middleware = [
  funnelMiddleware,
  apiMiddleware,
  inventoryMiddleware,
  movePlanMiddleware,
  modalsMiddleware,
  compareMiddleware,
  consultationsMiddleware,
  bookMiddleware,
  authMiddleware,
  detailsMiddleware,
  planMiddleware,
  moverMiddleware,
  congratsMiddleware,
  socialMiddleware,
  myHomeMiddleware,
  commonMiddleware,
  analyticsMiddleware
];

if (true) {
  middleware.push(createLogger({ collapsed: true }));
}

// todo: add only relevant data to be stored in local storage using blacklist and whitelist
const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['modals', 'router', 'funnel', 'movePlan', 'api', 'common', 'inventory', 'book', 'auth' ]
};

const storageReducer = persistReducer(persistConfig, createRootReducer(history));

const store = createStore(
  storageReducer,
  composeWithDevTools(applyMiddleware(
    routerMiddleware(history),
    ...middleware
  )),
);

export default store;
