import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import PageNotFound from '../common/PageNotFound';
import { connect } from 'react-redux';

// new
const AsyncInventoryIntro = lazy(() => import("../newMoving/inventory/InventoryIntro"));
const AsyncInventory = lazy(() => import("../newMoving/inventory/Inventory"))
const AsyncAddInventory = lazy(() => import("../newMoving/inventory/AddInventory"))
const AsyncAddBox = lazy(() => import("../newMoving/inventory/AddBox"))
const AsyncCompare = lazy(() => import("../newMoving/compare/Compare"))
const AsyncExtraServices = lazy(() => import("../newMoving/extraServices/ExtraServices"))
const AsyncBoxesDeliveryMenu = lazy(() => import("../newMoving/extraServices/BoxesDeliveryMenu"))
const AsyncReviewTotal = lazy(() => import("../newMoving/reviewTotal/ReviewTotal"))
const AsyncPaymentMethods = lazy(() => import("../newMoving/payment/PaymentMethods"))
const AsyncCreateAccount = lazy(() => import("../newMoving/reviewTotal/CreateAccount"))
const AsyncLoginAccount = lazy(() => import("../newMoving/reviewTotal/LoginAccount"))
const AsyncPayment = lazy(() => import("../newMoving/payment/Payment"))
const AsyncPayPalBookPage = lazy(() => import("../newMoving/payment/PayPalBookPage"))
const AsyncPlan = lazy(() => import("../newMoving/review/Review"))
const AsyncWhatIsNext = lazy(() => import("../newMoving/payment/WhatNext"))

const mapStateToProps = ({ movePlan, router }) => ({
  mpid: movePlan.currentMPID,
  isOverviewPage: router.location.pathname.indexOf('overview') >= 0
});

const MovingContainer = props => (
  <div>
    <Switch>
      {/* new */}
      <Route path="/moving/:mpid/inventoryintro" render={() => <Suspense fallback={<Loader />}><AsyncInventoryIntro {...props} /></Suspense>} />
      <Route exact path="/moving/:mpid/inventory" render={() => <Suspense fallback={<Loader />}><AsyncInventory {...props} /></Suspense>} />
      <Route exact path="/moving/:mpid/inventory/add-item" render={() => <Suspense fallback={<Loader />}><AsyncAddInventory {...props} /></Suspense>} />
      <Route exact path="/moving/:mpid/inventory/add-box" render={() => <Suspense fallback={<Loader />}><AsyncAddBox {...props} /></Suspense>} />
      <Route exact path="/moving/:mpid/compare" render={() => <Suspense fallback={<Loader />}><AsyncCompare {...props} /></Suspense>} />
      <Route exact path="/moving/:mpid/extra-services" render={() => <Suspense fallback={<Loader />}><AsyncExtraServices {...props} /></Suspense>} />
      <Route exact path="/moving/:mpid/box-delivery" render={() => <Suspense fallback={<Loader />}><AsyncBoxesDeliveryMenu {...props} /></Suspense>} />
      <Route exact path="/moving/:mpid/review-total" render={() => <Suspense fallback={<Loader />}><AsyncReviewTotal {...props} /></Suspense>} />
      <Route exact path="/moving/:mpid/payment-methods" render={() => <Suspense fallback={<Loader />}><AsyncPaymentMethods {...props} /></Suspense>} />
      <Route exact path="/moving/:mpid/create-account" render={() => <Suspense fallback={<Loader />}><AsyncCreateAccount {...props} /></Suspense>} />
      <Route exact path="/moving/:mpid/login" render={() => <Suspense fallback={<Loader />}><AsyncLoginAccount {...props} /></Suspense>} />
      <Route path="/moving/:mpid/book" render={() => <Suspense fallback={<Loader />}><AsyncPayment {...props} /></Suspense>} />
      <Route path="/moving/:mpid/what-is-next" render={() => <Suspense fallback={<Loader />}><AsyncWhatIsNext {...props} /></Suspense>} />
      <Route path="/moving/:mpid/book-paypal" render={() => <Suspense fallback={<Loader />}><AsyncPayPalBookPage {...props} /></Suspense>} />
      <Route path="/moving/:mpid/plan" render={() => <Suspense fallback={<Loader />}><AsyncPlan {...props} /></Suspense>} />
      <Route component={PageNotFound} />
    </Switch>
  </div>
);

export const Loader = () => <img alt="loading" className='loader-gif' src="/img/compare/compare_loader.gif"   />

export default connect(mapStateToProps)(MovingContainer);