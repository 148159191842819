import React, { useEffect } from 'react'
import CloseButtonRow from '../../common/CloseButtonRow'
import CaseyWords from '../../movingDetails/CaseyWords'
import { useFormik } from 'formik';
import ThirdPartySignin from './ThirdPartySignin';
import { connect } from 'react-redux';
import { checkEmailAvailability, setEmailTaken, registerUser } from '../../../redux/actions/auth';
import ReactLoading from 'react-loading';
import { push } from 'connected-react-router';
import { Label, Input } from 'reactstrap';

const mapStateToProps = ({ auth }) => ({
  isEmailTaken: auth.isEmailTaken,
  submitting: auth.submitting,
});

const CreateAccount = (props) => {
  const mpid = props.match.params.mpid

  useEffect(() => {
    props.setEmailTaken(false);
    //eslint-disable-next-line
  }, []);

  const handleBlurCheckEmail = e => {
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {      
      props.checkEmailAvailability(e.target.value.toLowerCase());
    }
  };

  const handleFocusEmail = () => {
    if (!!props.isEmailTaken) {
      props.setEmailTaken(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      password_confirmation: '',
      agreement: false
    },
    ////////////// submit ////////////
    handleSubmit: (e) => e.preventDefault(),
    onSubmit: values => {
      if (props.isEmailTaken) return
      let { email, password, password_confirmation } = values;
      let payload = {
        email: email,
        password: password,
        password_confirmation: password_confirmation
      }
      payload.registration_domain = "Unpakt";
      if (mpid) {
        props.registerUser(payload, { context: 'review-total', mpid: mpid });
      } else if (!!sessionStorage.getItem('prevPage')) {
        let prevPage = sessionStorage.getItem('prevPage')
        props.registerUser(payload, { context: prevPage });
      } else {
        props.registerUser(payload, { context: `/` });
      }
    },

    ////////////// validate ////////////
    validateOnBlur: false,
    validateOnChange: false,
    validate: values => {
      let errors = {}
      if (values.password !== values.password_confirmation) errors.password = 'Passwords do not match.'
      if (!values.agreement) errors.agreement = 'You must agree to the terms and conditions'
      return errors
    }
  });

  return (
    <div className='moving-details-padding' >
      {mpid ?
        <CloseButtonRow prevPath='review-total' mpid={mpid} />
        :
        <div style={{ height: '8vh' }}></div>
      }
      <CaseyWords words='Let’s create an account to save your move plan' />
      <div className="create-account__container mt-3">
        <p className='hel-font__text create-account__title'>Email Address</p>
        <form onSubmit={formik.handleSubmit}>
          <div className={`account-info__row ${props.isEmailTaken && 'red-error-border'}`}>
            <input
              name="email"
              type="text"
              value={formik.values.email}
              placeholder='example@youremail.com'
              className='account-info__input hel-font__text'
              onChange={formik.handleChange}
              onBlur={e => handleBlurCheckEmail(e)}
              onFocus={handleFocusEmail}
              required
            />
            {props.isEmailTaken && <div className='red-error-message'>Email is taken</div>}
          </div>
          <div className='account-info__row'>
            <input
              name="password"
              type="password"
              placeholder='Password'
              className='account-info__input hel-font__text'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={() => formik.touched}
              value={formik.values.password}
              required
            />
          </div>
          <div className={`account-info__row ${formik.errors.password && formik.touched.password_confirmation && 'red-error-border'}`}>
            <input
              name="password_confirmation"
              type="password"
              placeholder='Confirm Password'
              className='account-info__input hel-font__text'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={() => formik.touched}
              value={formik.values.password_confirmation}
              required
            />
            {formik.errors.password && formik.touched.password_confirmation && <div className='red-error-message'>{formik.errors.password}</div>}
          </div>

          {mpid ? 
          <p className='underline' onClick={() => props.push(`/moving/${mpid}/login`)} >Already have an account?</p>
          :
          <p className='underline' onClick={() => props.push(`/login`)} >Already have an account?</p>
          }
          

          <div className='accept-terms__row'>
            <Label check>
              <Input
                type="checkbox"
                name="agreement"
                onChange={formik.handleChange}
                value={formik.values.agreement}
                checked={formik.values.agreement === true}
              />{' '}
              <span className='accept-terms__text prox-font--regular'>
                I have read, understand and accept the
                <a
                  className="accept-terms__link underline prox-font--regular"
                  href={`${process.env.REACT_APP_MARKETING_URL}/agreement`}
                  target="_blank"
                  rel="noopener noreferrer">
                  {' '}
                  Terms and Conditions
                </a>
              </span>
            </Label>
            {formik.errors.agreement &&
              <div className='red-error-message'>{formik.errors.agreement}</div>
            }
          </div>


          <button
            className='button--long--round hel-font__text'
            style={{ fontSize: '2.3vh', color: 'black' }}
            type="submit"
            disabled={props.submitting || props.isEmailTaken}
          >
            {props.submitting ?
              <ReactLoading className="m-auto" type={"spokes"} color={"#ddd"} height={20} width={20} />
              :
              'Register'
            }

          </button>
        </form>
      </div>

      <ThirdPartySignin />
    </div>
  )
}

export default connect(mapStateToProps, { checkEmailAvailability, setEmailTaken, registerUser, push })(CreateAccount)
